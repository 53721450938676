/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 3.2.1263 on 2024-10-03 19:30:25.

export interface AdminSettings {
    settingsId: number;
    settings: string;
}

export interface AdminSettingsResponse {
    adminSettings: AdminSettings[];
}

export interface AdminUpdateResponse {
    status: boolean;
}

export interface Alert {
    title: AlertTitle;
    lap: number;
    vehicleNumber?: string;
    type: AlertType;
    timestamp: number;
    description: string;
}

export interface AlertData extends Alert {
}

export interface AlertKey {
    seriesType: SeriesType;
    runType: RunType;
}

export interface AlertKt {
}

export interface AlertLiveStreamKey {
    alertType: AlertType;
    seriesType: SeriesType;
    runType: RunType;
}

export interface AlertMessage extends Alert, Message, Comparable<AlertMessage> {
}

export interface AlertReplayResponse {
    alertDataSet: Alert[];
}

export interface AlertRequest extends BaseRequest {
    flagRequest?: FlagAlertRequest;
    pitRequest?: PitAlertRequest;
    audioRequest?: AudioAlertRequest;
    lapSearchRequest?: LapSearchRequest;
    titleSearch?: TextSearchRequest[];
    descriptionSearch?: TextSearchRequest[];
    seriesType: SeriesType;
    runType: RunType;
    eventId: string;
}

export interface AudioAlertRequest {
    keywords?: string[];
    vehicleNumbers?: string[];
}

export interface BaseRequest {
    sessionId: string;
}

export interface BasicSession {
    seriesType: SeriesType;
    runType: RunType;
    raceId?: string;
    sessionId?: string;
}

export interface BasicSessionInfo extends BasicSession {
}

export interface Caution {
    race_id: number;
    start_lap: number;
    end_lap: number;
    reason: string;
    comment: string;
    beneficiary_car_number?: string;
    flag_state: number;
}

export interface ClearCacheRequest {
    forceClearCache: boolean;
}

export interface ClearCacheResponse {
    liveEventRunning: boolean;
}

export interface ClearCommonProcessorCacheResponse {
    status: boolean;
}

export interface ClientCrewMetadata {
    clients: ClientCrewMetadataList[];
}

export interface ClientCrewMetadataList {
    clientId: string;
    teamName: string;
    driverFullName: string;
    vehicleNumber: string;
    vehicleNickname: string;
}

export interface ConsumerKey {
    seriesType: SeriesType;
    originType: OriginType;
}

export interface DateRangeTimestamps {
    threeDaysPrior: number;
    oneDayAfter: number;
}

export interface DefaultWorkspaceResponse {
    workspaces: Record<SanctioningBodyEnum, Workspace>;
}

export interface DriverCompareTrack {
    trackId: string;
    trackWalls: string;
    trackLine: string;
    trackSectors: string;
    trackOrientation: string;
}

export interface DriverCompareWorkspace {
    userId: string;
    id: string;
    workspace: string;
    createdTimestamp?: Date;
    updatedTimestamp?: Date;
    slug: string;
    configName: string;
    displaySettings: string;
}

export interface DriverPointsData extends Message {
    vehicleNumber: string;
    deltaLeader?: number;
    deltaNext?: number;
    firstName?: string;
    driverId?: number;
    isInChase?: boolean;
    isPointsEligible?: boolean;
    isRookie?: boolean;
    lastName?: string;
    membershipId?: number;
    points?: number;
    pointsPosition?: number;
    pointsEarnedThisRace?: number;
    stageOnePoints?: number;
    stageOneWinner?: boolean;
    stageTwoPoints?: number;
    stageTwoWinner?: boolean;
    stageThreePoints?: number;
    stageThreeWinner?: boolean;
    wins?: number;
    topFive?: number;
    topTen?: number;
    poles?: number;
    preRaceRanking?: number;
    preRaceWins?: number;
    preRacePlayoffPoints?: number;
    preRacePoints?: number;
    currentPointsRanking?: number;
    bonusPoints?: number;
    accruedPoints?: number;
    pointsAtStart?: number;
    inChase: boolean;
    pointsEligible: boolean;
    rookie: boolean;
}

export interface DriverVehicleSeriesData {
    driverId: string;
    vehicleNumber: string;
    driverName: string;
    manufacturer: string;
    seriesId: string;
}

export interface DriverVehicleSeriesDataResponse {
    driverVehicleSeriesData: DriverVehicleSeriesData[];
}

export interface EditableLeader {
    position: number;
    vehicleNumber: string;
    driverFullName: string;
}

export interface EntriesData {
    runningPosition: number;
    vehicleNumber: string;
    driverFullName: string;
    qualifyingGroupName: string;
}

export interface EntriesDataResponse {
    entriesData: LapData[];
}

export interface Entry {
    position: number;
    vehicleNumber: string;
    driverFullName: string;
    qualifyingGroupName: string;
}

export interface EntryList {
    driver_id: number;
    crew_chief_id: number;
    crew_chief_fullname: string;
    team_id: number;
    team_comp_id: number;
    vehicle_number: string;
    driver_name: string;
    driver_hometown: string;
    hometown_city: string;
    hometown_state: string;
    hometown_country: string;
    owner_name: string;
    team_name: string;
    manufacturer: string;
    modified: Date;
    pit_crew: PitCrewMember[];
    road_crew: RoadCrewMember[];
    qualifying_group_order: number;
    qualifying_group?: string;
}

export interface Error {
    message: string;
    errorCode: string;
    clientRequestId: string;
}

export interface ErrorMessage {
    statusCode: number;
    message: string;
}

export interface Event {
    eventId: string;
    eventName: string;
    eventDate: string;
    trackId: string;
    trackEventId: string;
}

export interface EventDriverNameVehicleNumber {
    driverFullName: string;
    vehicleNumber: string;
}

export interface EventSessionDetailsResponse {
    session: Session;
    event: Event;
    track: Track;
}

export interface EventSessions {
    eventId: string;
    eventName: string;
    eventDate: string;
    trackId: string;
    trackName: string;
    trackEventId: string;
    sessionIds: string[];
}

export interface EventSessionsStatusResponse {
    eventId: string;
    sessionList: Session[];
}

export interface EventsResponse {
    events: EventSessions[];
    sessions: Session[];
}

export interface ExtensionsKt {
}

export interface FastDataResponse {
    fastDataMap: Record<number, LapAnalysisData>;
    defaultThreshold: number;
}

export interface FeedStatusData extends Message {
    feedStatus: FeedStatusType;
    feedStatusOrigin: OriginType;
    problematicPartitions: string[];
}

export interface FlagAlertRequest {
}

export interface FlagStatus extends Message {
    lapNumber: number;
    lapsRemaining: number;
    flag: FlagType;
    timestamp: number;
    elapsedRaceTime: number;
    greenFlagCount: number;
    yellowFlagCount: number;
    stages: Record<number, number>;
    stageNumber: number;
    stageLapNumber: number;
    messageType: string;
    timeRemaining: number;
}

export interface FlagTime {
    flagTime: string;
    flagType: string;
}

export interface GapMetadata {
    vehicleNumber: string;
    gap: number;
}

export interface GetSessionDBInfoRequest {
    sessionId: string;
}

export interface GetSessionDBInfoResponse {
    sessionDBInfo?: SessionDBInfo;
    status: boolean;
    message: string;
}

export interface GetWeekendSessionsRequest {
    sanctioningBodyId: SanctioningBodyEnum;
}

export interface GetWeekendSessionsResponse {
    status: boolean;
    message: string;
}

export interface GreenCalc {
    value: number;
    lineCrossing: string;
}

export interface GreenFlagTime {
    greenFlagTime: string;
    yellowFlagTime: string;
}

export interface GreenSingle {
    vehicleNumber: string;
    lapNumber: number;
    greenCalc: GreenCalc;
}

export interface ImageGalleryEventSettings {
    eventId: string;
    sessionToSettingsMap: Record<string, ImageGallerySessionSettings>;
}

export interface ImageGallerySessionSettings {
    order: number;
    folders: string[];
}

export interface IndyWindData {
    stationId: string;
    timestamp: number;
    windSpeed: number;
    adjWindDir: number;
    latitude: number;
    longitude: number;
}

export interface InsertQualifyingEntriesRequest {
    eventId?: string;
    raceId?: number;
    sessionId?: string;
}

export interface InsertQualifyingEntriesResponse {
    status: boolean;
    message: string;
}

export interface Lap extends Message {
    driverName: string;
    vehicleNumber: string;
    elapsedTime: number;
    lapNumber: number;
    position: number;
    flag: FlagType;
    lapTime: number;
    messageType: string;
    bestLapTime: number;
    gapToLeader: number;
    leadLap: number;
    lapsToLeader: number;
    lapsLeadCount: number;
    startFinish: boolean;
    previousLaps: LapMetadata[];
    status: string;
    pitLap: boolean;
    loopName: string;
    pitCycleMetadata: PitCycleMetadata;
    timeOfDay: number;
    pitPositionMetadata: PitPositionData;
    avgLapTime: number;
    fastRank: number;
    avgRank: number;
    isLuckyDog: boolean;
    gapMetadata: GapMetadata[];
    lastPit?: number;
    lapsSincePit?: number;
    tireType?: string;
    overtakeRemaining?: number;
    pitStatus?: boolean;
}

export interface LapAnalysisAvgSingle {
    vehicleNumber: string;
    avgLapTime: number;
    avgLapRank: number;
}

export interface LapAnalysisData {
    numLaps: number;
    threshold: number;
    lapData: Record<string, LapAnalysisSingle>;
}

export interface LapAnalysisRequest {
    requestedLapNumber?: number;
    threshold?: number;
    streamInfo: StreamInfo;
    isReplay: boolean;
    isConsecutive?: boolean;
    runName?: string;
}

export interface LapAnalysisSingle {
    vehicleNumber: string;
    lapTime: number;
    lapDiff: number;
    lapRank: number;
}

export interface LapAnalysisStats {
    slow: number;
    medium: number;
    fast: number;
}

export interface LapData extends SimpleLap, Message {
    pitCycleTimeMetadata: PitCycleTimeMetadata;
    pitPositionMetadata: PitPositionData;
    fastRank: number;
    avgRank: number;
    startFinish: boolean;
    pitLap: boolean;
}

export interface LapHistData extends Message {
    lap: number;
    leaderLapFlag: number;
    vehicleMetaDataList: VehicleMetaData[];
    driverCount: number;
    timestampMetadata: TimestampMetadata;
}

export interface LapLeader {
    start_lap: number;
    end_lap: number;
    car_number: string;
    race_id: number;
}

export interface LapMetadata {
    flag: FlagType;
    lapTime: number;
    lapNumber: number;
    position: number;
    slowLap: boolean;
    greenYellowFlag: boolean;
    pitLap: boolean;
    leaderFlag: FlagType;
    timeOfDay: number;
}

export interface LapSearchRequest {
    startingLap: number;
    endingLap: number;
    exclude: boolean;
}

export interface LatLong {
    latitude: number;
    longitude: number;
}

export interface LineCrossing {
    lineCrossingId: string;
    pb_long: number;
    lat: number;
    avgFrac: number;
    category: string;
}

export interface LiveStreamInfo {
    session: BasicSessionInfo;
    origins: OriginType[];
}

export interface LiveStreamKey {
    seriesType: SeriesType;
    runType: RunType;
    originType: OriginType;
}

export interface ManufacturerPoints {
    position: number;
    manufacturer: string;
    points: number;
}

export interface ManufacturerPointsList {
    manufacturerPointsList: ManufacturerPoints[];
}

export interface Message {
    sessionInfo: SessionInfo;
    messageInfo: MessageInfo;
}

export interface MessageInfo {
    messageType: MessageType;
    originType: OriginType;
}

export interface MinimalLap {
    vehicleNumber: string;
    runningPosition: number;
    elapsedTime: number;
    runName: string;
    isPitLap: boolean;
    lapsCompleted: number;
    lastLapTime: number;
    groupName: string;
    flag: FlagType;
    timeOfDay: number;
}

export interface MinimalLapData extends MinimalLap {
    pitLap: boolean;
}

export interface ModifySessionRequest extends ModifySessionRequestInterface {
}

export interface ModifySessionRequestInterface {
    sessionBlobPath: string;
    sessionOverrides: BasicSessionInfo[];
}

export interface ModifySessionResponse extends ModifySessionResponseInterface {
}

export interface ModifySessionResponseInterface {
    success: boolean;
    message: string;
}

export interface NascarTrailerIrTemp {
    stationName: string;
    timestamp: number;
    summaryHighLine: number;
    summaryMidLine: number;
    summaryLowLine: number;
    summaryReference: number;
    pngImageData: number;
    eventId: string;
}

export interface NascarTrailerWeather {
    id: number;
    type: string;
    timestamp: number;
    station: string;
    data: NascarTrailerWeatherData;
}

export interface NascarTrailerWeatherData {
    looHeader: boolean;
    barTrend: number;
    packetType: string;
    barometer: number;
    insideTemperature: number;
    outsideTemperature: number;
    windSpeed: number;
    windDirection: number;
    tenMinAvgWindSpeed: number;
    twoMinAvgWindSpeed: number;
    windDirTenMinWindGust: number;
    barometricSensorRawReading: number;
    dewPoint: number;
    outsideHumidity: number;
    heatIndex: number;
    windChill: number;
    thswIndex: number;
    rainRate: number;
    uvIndex: number;
    solarRadiation: number;
    stormRain: number;
    startDateOfStorm: string;
    dailyRain: number;
    last15MinRain: number;
    lastHourRain: number;
    dailyEt: number;
    last24HourRain: number;
    barometricReductionMethod: number;
    userBarometricOffset: number;
    barometricCalibrationNumber: number;
    absoluteBarometricPressure: number;
    altimeterSetting: number;
    adr: number;
    tenMinWindGust: number;
    insideHumidity: number;
    saeCorrectionFactor: number;
}

export interface NascarTrailerWeatherHistoricalListingRowData {
    recordingId: string;
    trackName: string;
    trackTimezone: string;
    recordingDateRange: string;
}

export interface OwnerPoints extends Message {
    ownerName: string;
    points: number;
    bonusPoints: number;
    starts: number;
    poles: number;
    wins: number;
    top5: number;
    top10: number;
    dnf: number;
    rank: number;
    ownerId: number;
    ownerFirstName: string;
    ownerLastName: string;
    vehicleNumber: string;
}

export interface OwnerPointsList {
    ownerPointsList: OwnerPoints[];
}

export interface PitAlertRequest {
    vehicleNumbers?: string[];
}

export interface PitCrewMember {
    full_name: string;
    position: string;
    home_track: string;
    age: number;
    member_id: number;
}

export interface PitCycleMetadata {
    pitCycleTimes: PitCycleTimeMetric[];
    bestPitInCycleTime: number;
    averagePitInCycleTime: number;
    bestPitOutCycleTime: number;
    averagePitOutCycleTime: number;
    bestFullCycleTime: number;
    averageFullCycleTime: number;
    best4TFullCycleTime: number;
    average4TFullCycleTime: number;
    best2TFullCycleTime: number;
    average2TFullCycleTime: number;
    bestFuelOnlyFullCycleTime: number;
    averageFuelOnlyFullCycleTime: number;
}

export interface PitCycleTimeMetadata {
    bestPitInCycleTime: number;
    averagePitInCycleTime: number;
    bestPitOutCycleTime: number;
    averagePitOutCycleTime: number;
    bestFullCycleTime: number;
    averageFullCycleTime: number;
    bestFourTireFullCycleTime: number;
    averageFourTireFullCycleTime: number;
    bestTwoTireFullCycleTime: number;
    averageTwoTireFullCycleTime: number;
    bestFuelOnlyFullCycleTime: number;
    averageFuelOnlyFullCycleTime: number;
}

export interface PitCycleTimeMetric {
    lapNumber: number;
    pitIn: number;
    pitOut: number;
    fullCycle: number;
    tiresChanged: number;
}

export interface PitData extends Message {
    vehicleNumber: string;
    lapNumber: number;
    flagIn: FlagType;
    flagOut: FlagType;
    leftTime: number;
    rightTime: number;
    jackTime: number;
    crewTime: number;
    driverTime: number;
    driverEnter: number;
    driverGoes: number;
    carStopToJack: number;
    jackToCarGoes: number;
    totalTime: number;
    tiresChanged: number;
    pitInTime: number;
    pitOutTime: number;
    missedEvents: number;
    sequence: string;
    bestDriverTime: number;
    avgDriverTime: number;
    bestTotal2T: number;
    bestTotal4T: number;
    bestStop2T: number;
    bestStop4T: number;
    avgStop2T: number;
    avgStop4T: number;
    bestCrew2T: number;
    bestCrew4T: number;
    avgCrew2T: number;
    avgCrew4T: number;
    bestJackLeftTime: number;
    bestJackRightTime: number;
    avgJackLeftTime: number;
    avgJackRightTime: number;
    bestJackManTime: number;
    avgJackManTime: number;
    driverTimes: number[];
    stopTimes2T: number[];
    stopTimes4T: number[];
    totalTimes2T: number[];
    totalTimes4T: number[];
    crewTimes2T: number[];
    crewTimes4T: number[];
    jackLeftTimes: number[];
    jackRightTimes: number[];
    jackManTimes: number[];
    leaderLap: number;
    driverName: string;
    pitPositionMetadata: PitPositionData;
    greenIn: GreenCalc[];
    greenOut: GreenCalc[];
    recordedTime: number;
    boxLeaveRaceTime: number;
    boxStopRaceTime: number;
    driverId: number;
    pitInTravelDuration: number;
    pitOutTravelDuration: number;
    pitInFlagStatus: FlagType;
    pitOutFlagStatus: FlagType;
    pitInPassingTime: number;
    pitInRaceTime: number;
    pitOutRaceTime: number;
    pitStopReportId: number;
    manufacturer: string;
    changedDatetime: number;
    externalFactor: string;
    fuelStrategy: string;
    infractions: string[];
    isVerified: boolean;
    isInvalid: boolean;
    pitStopDuration?: number;
    pitStopType?: string;
    proPitStopType?: string;
    numberOfFuelCans?: number;
    leftSideAdjust?: boolean;
    rightSideAdjust?: boolean;
    rearLeftTireChanged?: boolean;
    rearRightTireChanged?: boolean;
    frontLeftTireChanged?: boolean;
    frontRightTireChanged?: boolean;
    pitBoxStopTime?: number;
    pitBoxLeaveTime?: number;
    bodyRepairs?: boolean;
    source: PitStrategySource;
    verified: boolean;
    invalid: boolean;
}

export interface PitPositionData {
    pitInLap: number;
    pitInPosition: number;
    pitOutPosition: number;
    pitPositionDifference: number;
    avgPitInPosition: number;
    avgPitOutPosition: number;
    avgPitPositionDifference: number;
}

export interface PitRoadAnalysis {
    vehicleNumber: string;
    driverFullName: string;
    bestTotalFourTires: number;
    bestTotalTwoTires: number;
    bestStopTwoTires: number;
    bestStopFourTires: number;
    avgStopTwoTires: number;
    avgStopFourTires: number;
    bestDriverTime: number;
    avgDriverTime: number;
    bestCrewTwoTires: number;
    bestCrewFourTires: number;
    avgCrewTwoTires: number;
    avgCrewFourTires: number;
    bestJackLeftTime: number;
    bestJackRightTime: number;
    avgJackLeftTime: number;
    avgJackRightTime: number;
    bestJackManTime: number;
    avgJackManTime: number;
}

export interface PitRoadData extends Message {
    vehicleNumber: string;
    lap: number;
    flagIn: string;
    flagOut: string;
    leftTime: number;
    rightTime: number;
    jackTime: number;
    crewTime: number;
    driverTime: number;
    driverEnter: number;
    driverGoes: number;
    vehicleStopToJack: number;
    jackToVehicleGoes: number;
    totalTime: number;
    tiresChanged: number;
    pitInTime: number;
    pitOutTime: number;
    missedEvents: number;
    sequence: string;
    leaderLap: number;
    driverFullName: string;
    pitStopType?: string;
    proPitStopType?: string;
    numberOfFuelCans?: number;
    leftSideAdjust?: boolean;
    rightSideAdjust?: boolean;
    rearLeftTireChanged?: boolean;
    rearRightTireChanged?: boolean;
    frontLeftTireChanged?: boolean;
    frontRightTireChanged?: boolean;
    pitBoxStopTime?: number;
    pitBoxLeaveTime?: number;
    pitStopDuration?: number;
    bodyRepairs?: boolean;
    infractions: string[];
    pitPositionMetadata: PitPositionData;
    greenInList: GreenCalc[];
    greenOutList: GreenCalc[];
}

export interface PitStrategy {
    flagIn: FlagType;
    flagOut: FlagType;
    leftTime: number;
    rightTime: number;
    jackTime: number;
    crewTime: number;
    driverEnterTime: number;
    driverTime: number;
    totalTime: number;
    tires: number;
    pitInTime: number;
    pitOutTime: number;
    missedEvents: number;
    sequence: string;
    carStopToJack: number;
    jackToCarGoes: number;
    driverGoes: number;
    pitStopType?: string;
    recordedTime: number;
    boxLeaveRaceTime: number;
    boxStopRaceTime: number;
    driverId: number;
    pitInTravelDuration: number;
    pitOutTravelDuration: number;
    pitInFlagStatus: FlagType;
    pitOutFlagStatus: FlagType;
    pitInPassingTime: number;
    pitInRaceTime: number;
    pitOutRaceTime: number;
    pitStopReportId: number;
    manufacturer: string;
    pitBoxStopTime?: number;
    pitBoxLeaveTime?: number;
    pitStopDuration?: number;
    changedDatetime: number;
    externalFactor: string;
    fuelStrategy: string;
    infractions: string[];
    rearLeftTireChanged?: boolean;
    rearRightTireChanged?: boolean;
    frontLeftTireChanged?: boolean;
    frontRightTireChanged?: boolean;
    numberOfFuelCans?: number;
    leftSideAdjust?: boolean;
    rightSideAdjust?: boolean;
    bodyRepairs?: boolean;
    isVerified?: boolean;
    isInvalid: boolean;
    source: PitStrategySource;
    verified: boolean;
    invalid: boolean;
}

export interface PitStrategyData extends Message {
    vehicleNumber: string;
    lap: number;
    leaderLap: number;
    driverName: string;
    pitStrategySourceMap: Record<PitStrategySource, PitStrategy>;
}

export interface PitStrategyDataResponse {
    pitStrategyDataList: PitStrategyData[];
}

export interface PreviousLapMetaData {
    lap: number;
    flag: FlagType;
    lapTime: number;
    slowLap: boolean;
}

export interface ProcessRaceReplayResponse {
    liveEventRunning: boolean;
    message: string;
    functionError: boolean;
}

export interface ProcessReplayRequest {
    sessionId?: string;
    originType: OriginType;
    eventId: string;
    runType: RunType;
    seriesType: SeriesType;
}

export interface QualifyingEntriesData {
    runningPosition: number;
    vehicleNumber: string;
    driverFullName: string;
    qualifyingGroupName: string;
}

export interface QualifyingEntriesDataResponse {
    qualifyingEntriesData: QualifyingEntriesData[];
}

export interface QualifyingLapData {
    runningPosition: number;
    vehicleNumber: string;
    driverFullName: string;
    deltaToTarget: number;
    lastLapTime: number;
    bestLapTime: number;
    timeOfDay: number;
    runName: string;
    isDatabaseRecord: boolean;
    vehicleStatus: string;
    messageType: string;
    isStartFinish: boolean;
    qualifyingPosition: number;
    lapsCompleted: number;
    startFinish: boolean;
    databaseRecord: boolean;
}

export interface QualifyingMetaData {
    groupsList: string[];
    totalRounds: number;
    lapsPerRound: number;
    isTimedSession: boolean;
    seriesId: string;
    timedSession: boolean;
}

export interface Race {
    runs: RunDetail[];
    race_id: number;
    series_id: number;
    race_season: number;
    race_name: string;
    race_type_id: number;
    restrictor_plate: boolean;
    track_id: number;
    track_name: string;
    date_scheduled: string;
    race_date: string;
    qualifying_date: string;
    tunein_date: string;
    scheduled_distance: number;
    actual_distance: number;
    scheduled_laps: number;
    actual_laps: number;
    stage_1_laps: number;
    stage_2_laps: number;
    stage_3_laps: number;
    number_of_cars_in_field: number;
    pole_winner_driver_id: number;
    pole_winner_speed: number;
    number_of_lead_changes: number;
    number_of_leaders: number;
    number_of_cautions: number;
    number_of_caution_laps: number;
    average_speed: number;
    total_race_time: string;
    margin_of_victory: string;
    race_purse: number;
    race_comments: string;
    attendance: number;
    results: RaceResult[];
    caution_segments: Caution[];
    race_leaders: LapLeader[];
    infractions: RaceInfraction[];
    schedule: RaceSchedule[];
    radio_broadcaster: string;
    television_broadcaster: string;
    satellite_radio_broadcaster: string;
    master_race_id: number;
    inspection_complete: boolean;
    playoff_round: number;
}

export interface RaceDataResult {
    actualLaps: number;
    scheduledLaps: number;
    stageOneLaps: number;
    stageTwoLaps: number;
    stageThreeLaps: number;
    stageFourLaps: number;
}

export interface RaceInfraction {
    car_number?: string;
    driver_fullname?: string;
    driver_id: number;
    lap: number;
    lap_assessed: number;
    infraction: string;
    penalty: string;
    notes: string;
}

export interface RaceProgressionData extends SessionProgression, Message {
}

export interface RaceResult {
    result_id: number;
    finishing_position: number;
    starting_position: number;
    car_number: string;
    driver_fullname: string;
    driver_id: number;
    driver_hometown: string;
    hometown_city: string;
    hometown_state: string;
    hometown_country: string;
    team_id: number;
    team_name: string;
    qualifying_order: number;
    qualifying_position: number;
    qualifying_speed: number;
    laps_led: number;
    times_led: number;
    car_make: string;
    car_model: string;
    sponsor: string;
    points_earned: number;
    bonus_points_earned: number;
    playoff_points_earned: number;
    laps_completed: number;
    finishing_status: string;
    winnings: number;
    series_id: number;
    race_season: number;
    race_id: number;
    owner_fullname: string;
    crew_chief_id: number;
    crew_chief_fullname: string;
    points_position: number;
    points_delta: number;
    owner_id: number;
    official_car_number: string;
    disqualified: boolean;
    diff_laps: number;
    diff_time: number;
    pit_box: number;
}

export interface RaceSchedule {
    event_name: string;
    notes: string;
    start_time_utc: string;
    run_type: number;
}

export interface RankAnalysisResponse {
    vehicleToLapMap: Record<string, Record<number, number>>;
    lapAnalysisMap: Record<number, LapAnalysisData>;
    lapAnalysisAverageMap: Record<string, LapAnalysisAvgSingle>;
    defaultLapAnalysisStats: LapAnalysisStats;
}

export interface ReplayProgressionRequest {
    eventId: number;
    sessionType: RunType;
}

export interface ReplayRequest extends BaseRequest {
    eventId: string;
    sessionType: RunType;
}

export interface RequestCounter {
    count: number;
}

export interface RoadCrewMember {
    full_name: string;
    position: string;
    home_track: string;
    age: number;
    member_id: number;
}

export interface Run {
    id: number;
    event_id: number;
    race_uid: number;
    series: string;
    series_uid: number;
    name: string;
    type: number;
    ttl_laps: number;
    laps_rem: number;
    dur: number;
    tm_rem: number;
    act_start: string;
    sch_start: string;
    is_active: boolean;
    is_complete: boolean;
    complete: boolean;
    active: boolean;
}

export interface RunDetail {
    run_id: number;
    run_name: string;
    run_type: number;
    run_type_description: string;
    finish_at_lap: number;
    start_time: string;
    results: RunResult[];
}

export interface RunResult {
    result_id: number;
    starting_position: number;
    finishing_position: number;
    car_number: string;
    driver_fullname: string;
    competitor_id: number;
    make: string;
    sponsor: string;
    best_laptime: number;
    best_lap: number;
    best_speed: number;
    last_laptime: number;
    last_speed: number;
    laps: number;
    total_time: number;
}

export interface SanctioningBody {
    sanctioningBodyId: string;
    sanctioningBodyName: string;
    sanctioningBodySeriesIdsList: string[];
    sourceTypesList: number[];
}

export interface SanctioningBodyResponse {
    sanctioningBodiesList: SanctioningBody[];
    sanctioningBodySeriesList: SanctioningBodySeries[];
}

export interface SanctioningBodySeries {
    seriesId: string;
    seriesName: string;
}

export interface Schedule {
    start_time: string;
    end_time: string;
    event_name: string;
    race_id: number;
    track_id: number;
    track_name: string;
    race_name: string;
    series_id: number;
    run_type: number;
    start_time_utc: string;
    end_time_utc: string;
}

export interface Series {
    seriesId: string;
    seriesFullName: string;
    seriesShortName: string;
    seriesAbbreviation: string;
    seasons: string[];
}

export interface Session {
    sessionId: string;
    sessionType: RunType;
    sessionStatus: RunStatus;
    seriesId: string;
    sessionDescription: string;
}

export interface SessionCacheKey {
    seriesId: number;
    eventId: number;
    season: number;
}

export interface SessionDBInfo {
    sessionId: string;
    raceId: number;
    eventId: string;
    eventGroupId: string;
    sessionType: RunType;
    sequence: number;
    scheduledStartTimestamp?: Date;
    timeAllottedGroupA: number;
    timeAllottedTotal: number;
    description: string;
    numberOfLaps: number;
    raceStatusId: RunStatus;
}

export interface SessionInfo {
    eventId: string;
    raceId?: number;
    runType: RunType;
    seriesId: string;
    eventName: string;
    feedSpecificRunName: string;
    seriesType: SeriesType;
    clientId: string;
    sessionId?: string;
    originType: OriginType;
}

export interface SessionPitRoadAnalysis {
    leaderLap: number;
    pitRoadAnalysis: PitRoadAnalysis[];
}

export interface SessionPitRoadAnalysisRequest {
    eventId: string;
    sessionType: RunType;
}

export interface SessionPitRoadAnalysisResponse {
    pitRoadAnalysis: SessionPitRoadAnalysis[];
}

export interface SessionPitRoadSummary {
    leaderLap: number;
    pitRoadData: PitRoadData[];
}

export interface SessionPitRoadSummaryResponse {
    pitRoadSummaryList: SessionPitRoadSummary[];
}

export interface SessionPitStrategyRequest extends ReplayRequest {
}

export interface SessionPitSummaryRequest extends ReplayRequest {
    originType: OriginType;
}

export interface SessionProgression {
    currentStage: number;
    lapNumber: number;
    timeRemaining: number;
    totalLaps: number;
    timeElapsed: number;
    greenLapsFlagCount: number;
    yellowLapsFlagCount: number;
    totalStages: number;
    currentStageCurrentLap: number;
    currentStageTotalLaps: number;
    flag: number;
    localTime: number;
}

export interface SessionProgressionData extends SessionProgression {
}

export interface SessionProgressionRequest extends ReplayRequest {
}

export interface SessionProgressionResponse {
    progressionData: RaceProgressionData[];
}

export interface SessionRunStatus {
    sessionStatus: RunStatus;
}

export interface SessionTimingScoringData {
    leaderLap: number;
    leaderLapFlag: number;
    timestampMetadata: TimestampMetadata;
    sessionVehicleMetadataList: SessionTimingScoringVehicleMetaData[];
    driverCount: number;
    qualifyingRoundName: string;
}

export interface SessionTimingScoringRequest extends ReplayRequest {
}

export interface SessionTimingScoringResponse {
    sessionTimingScoringDataList: SessionTimingScoringData[];
}

export interface SessionTimingScoringVehicleMetaData {
    lapsCompleted: number;
    vehicleNumber: string;
    runningPosition: number;
    lastLapTime: number;
    slowLap: boolean;
    pitLap: boolean;
    flag: number;
    greenYellowFlag: boolean;
    fastestPosition: number;
    driverFullName: string;
    timeOfDay: number;
    deltaToTarget: number;
    lapsLed: number;
    bestLapTime: number;
    movingAverageLapTime: number;
    lapsToTarget: number;
    vehicleStatus: string;
    messageType: string;
    isStartFinish: boolean;
    avgLapTime: number;
    fastRank: number;
    avgRank: number;
    runName: string;
    qualifyingPosition: number;
    isDatabaseRecord: boolean;
    gapmetadataList: GapMetadata[];
    elapsedTime: number;
    bestLap: number;
    startFinish: boolean;
    databaseRecord: boolean;
}

export interface SimpleLap extends MinimalLap {
    pitStrategySource?: PitStrategySource;
    driverFullName: string;
    leaderLap: number;
    pitStopType?: string;
    bestLapTime: number;
    avgLapTime: number;
    loopName: string;
    messageType: string;
    tireType?: string;
    lapsSincePit?: number;
    lastPit?: number;
    pitStatus?: boolean;
    overtakeRemaining?: number;
    isStartFinish: boolean;
    deltaToTarget: number;
    lapsLed: number;
    movingAverageLapTime: number;
    lapsToTarget: number;
    vehicleStatus: string;
    gapmetadataList: GapMetadata[];
    slowLap: boolean;
    greenYellowFlag: boolean;
    fastestPosition: number;
    bestLap: number;
}

export interface SimpleLapData extends SimpleLap {
    startFinish: boolean;
    pitLap: boolean;
}

export interface StreamHistoryRequest {
    originType: number;
    seriesType: number;
    runType: number;
    eventId: string;
}

export interface StreamInfo extends BasicSession {
    originType: OriginType;
}

export interface StreamInfoKt {
}

export interface StreamRequestParams {
    paramsSet: boolean;
}

export interface SupportedStreams {
    sanctioningBody: SanctioningBodyEnum;
    supportedOrigins: OriginType[];
}

export interface TeamData {
    teamId: number;
    teamName: string;
    domain: string;
}

export interface Telemetry extends Message {
    vehicleNumber: string;
    vehicleManufacturer: string;
    latitude: number;
    longitude: number;
    altitude: number;
    positionStandardDeviation: number;
    gpsQuality: number;
    rpm: number;
    speed: number;
    throttle: number;
    brakePSI: number;
    steering: number;
    heading: number;
    lateralG: number;
    longitudeG: number;
    lapElapsedFraction: number;
    lapTiming: number;
    rpmFlag: boolean;
    throttleFlag: boolean;
    brakeFlag: boolean;
    steeringFlag: boolean;
    messageType: string;
    position: number;
    onLeadLap: boolean;
    isLuckyDog: boolean;
    gear: number;
}

export interface TelemetryData extends Message {
    vehicleNumber: string;
    vehicleManufacturer: string;
    latitude: number;
    longitude: number;
    heading: number;
    position: number;
    onLeadLap: boolean;
    isLuckyDog: boolean;
    gpsQuality: number;
    luckyDog: boolean;
}

export interface TextSearchRequest {
    searchTerm: string;
    exclude: boolean;
}

export interface TimestampMetadata {
    earliestTimestamp: number;
    latestTimestamp: number;
}

export interface Track {
    trackId: string;
    trackName: string;
    trackCity: string;
    trackState: string;
    trackLatitude: number;
    trackLongitude: number;
    trackZoom: number;
    trackBearing: number;
    trackTimezone: string;
    trackLineCrossingList: LineCrossing[];
    trackAddress: string;
    trackZip: string;
    trackType: string;
    trackSurface: string;
    trackBanking: string;
    trackLength: string;
    trackDescription: string;
    yearBuilt: string;
    frontstretchLength: string;
    backstretchLength: string;
    cautionCarSpeed: string;
    schStartTsUtc: string;
    schEndTsUtc: string;
    aclStartTsUtc: string;
    aclEndTsUtc: string;
    qualifyingmetadataList: QualifyingMetaData[];
}

export interface TrackLocationData extends Message {
    vehicleNumber: string;
    distFromSF: number;
    position: number;
    onLeadLap: boolean;
    isLuckyDog: boolean;
    isInPit: boolean;
    lap: number;
    luckyDog: boolean;
    inPit: boolean;
}

export interface TrackTempHistoricListResponse {
    trackTempHistoricList: TrackTemperatureHistoricalListing[];
}

export interface TrackTemperatureData extends Message {
    recordingName?: string;
    trackName?: string;
    cameraName?: string;
    cameraLocation?: string;
    cameraTakeTs?: number;
    lowLineTemp?: string;
    middleLineTemp?: string;
    highLineTemp?: string;
    referenceTemp?: string;
    imageReferenceName?: string;
    id?: number;
    loopId?: number;
    lineCrossingName?: string;
    triggerId?: number;
    tempF?: number;
    tempC?: number;
    lat?: number;
    lon?: number;
    timestamp?: number;
}

export interface TrackTemperatureHistoricalListing {
    recordingName: string;
    trackName: string;
    trackTimezone: string;
    recordingDateRange: string;
}

export interface TrackTemperatureImageData {
    cameraName: string;
    base64Image: string;
}

export interface TrackTemperatureImageRequest {
    recordingName: string;
    imageReferenceName: string;
}

export interface TrackTemperatureImageResponse {
    trackTemperatureRowImage: TrackTemperatureImageData;
}

export interface TrackTemperatureTableRequest {
    currentEventTrackId: string;
    currentEventLatestRowTimestamp: string;
    currentEventDate: string;
    isReplay: boolean;
}

export interface TrackTemperatureTableResponse {
    trackTemperatureRow: TrackTemperatureTabularRowData[];
}

export interface TrackTemperatureTabularRowData {
    trackRecordingName: string;
    trackName: string;
    cameraName: string;
    cameraLocation: string;
    cameraTakeTimestamp: string;
    lowLineTemperature: string;
    middleLineTemperature: string;
    highLineTemperature: string;
    referenceTemperature: string;
    imageReferenceName: string;
}

export interface TrackUpdateRequest extends TrackUpdateRequestInterface {
}

export interface TrackUpdateRequestInterface {
    update: string;
    trackId: number;
}

export interface UIProcessReplayRequest {
    sessionId: string;
    runType: RunType;
    raceProgressionOriginType?: OriginType;
    timingScoringOriginType?: OriginType;
    pitSummaryOriginType?: OriginType;
    totalLaps?: number;
}

export interface UpdateIndyStationsResponse {
    status: boolean;
    message: string;
}

export interface UpdatePostgresByRaceIdsRequest {
    raceIds: number[];
}

export interface UpdateSessionInfoRequest {
    sessionDBInfo: SessionDBInfo;
}

export interface UpdateSessionInfoResponse {
    status: boolean;
    message: string;
}

export interface UserPreferenceResponse {
    data: string;
    createdBy?: string;
    createdTimestamp?: Date;
    updatedBy?: string;
    updatedTimestamp?: Date;
}

export interface UserPreferenceResponseStatus {
    status: boolean;
}

export interface VehicleMetaData {
    vehicleNumber: string;
    runningPosition: number;
    lastLapTime: number;
    slowLap: boolean;
    pitLap: boolean;
    flag: number;
    greenYellowFlag: boolean;
    fastestPosition: number;
    driverFullName: string;
    timeOfDay: number;
    avgLapTime: number;
    fastRank: number;
    avgRank: number;
    fastLap: number;
    runName: string;
    elapsedTime: number;
    deltaToTarget: number;
    lapsLed: number;
    bestLapTime: number;
    movingAverageLapTime: number;
    lapsToTarget: number;
    vehicleStatus: string;
    isStartFinish: boolean;
    leaderLap: number;
    lapsCompleted: number;
    startFinish: boolean;
}

export interface VehicleOemListResponse {
    oemList: string[];
}

export interface WeatherData extends Message {
    airTemp?: string;
    solRad?: number;
    humidity?: number;
    barometer?: number;
    adr?: number;
    windSpeed?: number;
    windDirection?: number;
    trackTempFaht?: number;
    lat?: number;
    lon?: number;
    timestamp?: number;
    stationId?: string;
    looHeader: boolean;
    barTrend?: number;
    packetType?: string;
    insideTemperature?: number;
    insideHumidity?: number;
    outsideTemperature?: number;
    outsideHumidity?: number;
    tenMinAvgWindSpeed?: number;
    twoMinAvgWindSpeed?: number;
    tenMinWindGust?: number;
    windDirTenMinWindGust?: number;
    dewPoint?: number;
    heatIndex?: number;
    windChill?: number;
    rainRate?: number;
    stormRain?: number;
    startDateOfStorm?: string;
    dailyRain?: number;
    last15MinRain?: number;
    lastHourRain?: number;
    dailyET?: number;
    last24HourRain?: number;
    barometricReductionMethod?: number;
    userBarometricOffset?: number;
    barometricCalibrationNumber?: number;
    barometricSensorRawReading?: number;
    absoluteBarometricPressure?: number;
    altimeterSetting?: number;
    thswindex?: number;
    uvindex?: number;
    saecorrectionFactor?: number;
    THSWIndex?: number;
    UVIndex?: number;
    SAECorrectionFactor?: number;
}

export interface WeatherReplayResponse {
    weatherDataList: WeatherData[];
}

export interface Workspace {
    id: string;
    data: string;
    ownedBy: string;
    shared: boolean;
    createdBy: string;
    createdTimestamp: string;
    updatedBy: string;
    updatedTimestamp: string;
    sharedDomainsList: number[];
    ownedByDomain: string;
}

export interface WorkspaceInfo {
    id: string;
    name: string;
    slug: string;
    owner: string;
    shared: boolean;
    sharedDomains: number[];
    sanctioningBody: number;
}

export interface WorkspaceRequest {
    id: string;
    data: string;
    shared: boolean;
    sharedDomainsList: number[];
}

export interface WorkspaceResponseStatus {
    status: boolean;
}

export interface WorkspaceUpdateRequest extends WorkspaceUpdateRequestInterface {
}

export interface WorkspaceUpdateRequestInterface {
    workspaceId: string;
    workspaceUser: string;
    workspaceSettings: string;
    workspaceSlug: string;
    workspaceName: string;
    workspaceDisplay: string;
}

export interface AudioTranscriptionData extends Message {
    id: string;
    raceCode: string;
    raceSeries: string;
    raceSession: string;
    raceDate: string;
    driverName: string;
    driverNumber: string;
    audioStreamUrl: string;
    audioSnippetFilePath: string;
    transcriberSpeakerGroup: string;
    transcriberFinishedTimestampStr: string;
    transcriberFinishedTimestampMs: number;
    transcriberDisplayText: string;
    transcriberRecognitionStatus: string;
    transcriberOffset: number;
    transcriberDuration: number;
    transcriberSpeakerId: string;
    transcriberSpeakerType: string;
    transcriberUtteranceId: string;
    leaderLapNumber: number;
    categories: string[];
    copyTranscriberDisplayText: string;
}

export interface AudioTranscriptionRequest {
    eventId: string;
    runType: RunType;
    vehicleNumbers: string[];
    speakerRoles: SpeakersEnum[];
    searchQueries?: string[];
    categories?: string[];
    timestamp?: number;
    sort?: string;
    limit?: number;
}

export interface AudioTranscriptionStreamKey {
    eventId: string;
    runType: RunType;
}

export interface KeywordMatchedData {
    id: string;
    driverNumber: string;
    transcriberSpeakerGroup: SpeakersEnum;
    transcriberFinishedTimestampMs: number;
    transcriberDisplayText: string;
    leaderLapNumber: number;
    matchedKeywords: string[];
}

export interface AzureBlobSasTokenRequest {
    tokenType: number;
    eventDate?: string;
}

export interface DynamicImageRequest {
    eventId: string;
    filters?: Record<string, any>;
    pageNumber?: number;
    pageSize?: number;
    sort?: string;
}

export interface Image {
    searchScore?: string;
    metadataStoragePath?: string;
    filepath: string;
    metadataStorageLastModified?: string;
    creationTime?: string;
    imgName?: string;
    raceName?: string;
    sessionId?: string;
    photographerLocation?: string;
    detectedCars?: string[];
    lapNumber?: number;
    filteredText?: string[];
    numCarsDetected?: number;
    raceCode?: string;
    thumbnailUrl?: string;
}

export interface ImageFilter {
    mongoField: string;
    filterHeader: string;
    interactionType: InteractionType;
    valueMap?: Record<string, any[]>;
}

export interface ImageRequest {
    eventId: string;
    search?: string;
    sessionTypes?: string[];
    vehicles?: string[];
    numberOfVehicles?: string[];
    pageNumber?: number;
    pageSize?: number;
    sort?: string;
}

export interface ImageResponse {
    pageCount: number;
    imageCount: number;
    images: Image[];
}

export interface ImageStreamKey {
    eventId: string;
}

export interface Comparable<T> {
}

export enum AdminSettingsId {
    UNRECOGNIZED,
    ALL_ADMIN_SETTINGS,
    AUDIO_TRANSCRIPTION,
    DEFAULT_WORKSPACE,
    RACE_PROGRESSION_ORIGINS,
}

export enum AlertTitle {
    UNKNOWN,
    PIT_ENTRY,
    PIT_EXIT,
    KEYWORD_DETECTED,
    FLAG_CHANGE,
}

export enum AlertType {
    UNKNOWN,
    FLAG,
    PIT,
    AUDIO,
}

export enum FeedStatusType {
    UNKNOWN,
    GREEN,
    YELLOW,
    RED,
}

export enum FlagType {
    UNKNOWNFLAGTYPE,
    GREEN,
    YELLOW,
    RED,
    CHECKER,
    WHITE,
    STOP,
    REDBLACKCOMBO,
    WARM,
    UNFLAGGED,
}

export enum MapType {
    RING_OF_DOOM,
    LINE,
    MAP,
}

export enum MessageType {
    UNKNOWN,
    RACE_PROGRESSION,
    LAP,
    TELEMETRY,
    PIT,
    DRIVER_POINTS,
    TRACK_TEMPERATURE,
    WEATHER,
    FEED_STATUS,
    TRACK_LOCATION,
    AUDIO,
    OWNER_POINTS,
}

export enum OriginType {
    UNKNOWN,
    SMT,
    MULTILOOP,
    RMONITOR,
    ERDP,
    STRATEGY,
    RIS,
    WEATHER_STATION,
    FEED_STATUS,
    DATA_SCIENCE_AUDIO,
    LIVE,
}

export enum PitStrategySource {
    UNKNOWN,
    PROPENALTY,
    PITRHO,
    SMT,
}

export enum RunStatus {
    UNKNOWN_RUN_STATUS,
    COMPLETED,
    LIVE,
    UNAVAILABLE,
    DATA_LOSS,
    CANCELED,
    REPLAY_ENABLED,
}

export enum RunType {
    UNKNOWNRUNTYPE,
    PRACTICE,
    QUALIFYING,
    RACE,
    TEST_EVENT,
}

export enum SanctioningBodyEnum {
    UNKNOWN,
    NASCAR,
    INDYCAR,
    IMSA,
    F1,
}

export enum SeriesType {
    UNKNOWN,
    CUP,
    XFINITY,
    TRUCK,
    IndyCar,
    IndyLights,
    IndyPro,
    Usf2000,
    MX5Cup,
    Formula1,
}

export enum SourceType {
    AKKA_GRPC,
    TELEMETRY_ENGINE,
    EMULATOR,
}

export enum TrackType {
    ROAD_COURSE,
    ROAD_CIRCUIT,
    SPEEDWAY,
    SUPERSPEEDWAY,
    RACE_CIRCUIT,
    STREET_CIRCUIT,
    SHORT_TRACK,
}

export enum SpeakersEnum {
    Driver,
    Crew_Chief,
    Spotter,
    Unidentified,
}

export enum MongoFieldType {
    UNKNOWN,
    SINGLE,
    ARRAY,
    OBJECT,
}

export enum MongoValueType {
    UNKNOWN,
    STRING,
    NUMBER,
}

export enum SasTokenTypeEnum {
    UNKNOWN,
    AUDIO,
    IMAGE_VIEWER,
}

export enum InteractionType {
    UNKNOWN,
    SINGLE_SELECT,
    MULTI_SELECT,
    INT_RANGE,
    DOUBLE_RANGE,
    VEHICLE_SELECTION,
    ORIENTATION,
}